#lab-values-container {
    table {
        width: 100%;
        border: 0;
        border-collapse: collapse;
        @include shadow;
        background-color: var(--translucent);
        margin: 0 auto;
        border-radius: $borderRadius ;
        overflow: hidden;

        button {
            background-color: transparent;

            span {
                color: white;
            }
        }

        thead {
            background-color: var(--text);
            border: 0;
            border-radius: 25px;
            position: sticky;
            top: -15px;
            z-index: 1000;
            border-collapse: collapse;

            th {
                border: 0;
                padding: 10px 0;
                color: var(--bg);
                line-height: 1.2;
                width: 60%;
                position: relative;
                z-index: 1000;
                border-collapse: collapse;

                &:first-child {
                    width: 25%;
                    border-radius: $borderRadius 0 0 0;
                }

                &:last-child {
                    width: 15%;
                    padding: 0 15px;
                    border-radius: 0 $borderRadius 0 0;
                }
            }
        }

        tbody {
            // border: 2px solid var(--accent);

            td {
                line-height: 1.2;
                padding: 10px 5px;
                background-color: var(--bg);
                text-align: center;
                color: var(--text);
                font-size: 16px;
                background-color: var(--translucent);

                b {
                    color: var(--text);
                }
            }

            tr {
                border-bottom: 1px solid rgba(255, 255, 255, 0.1);

                &:last-child {
                    border-radius: 0 0 $borderRadius $borderRadius;
                    border-bottom: 0;

                    td {
                        &:first-child {
                            border-radius: 0 0 0 $borderRadius;
                        }

                        &:last-child {
                            border-radius: 0 0 $borderRadius 0;
                        }
                    }
                }
            }
        }
    }
}