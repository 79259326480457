@import "./_mixins.scss";

#app-container {
    position: relative;
    max-height: 100vh;
    overflow: auto;
    max-width: 100vw;
    width: 100vw;
    height: 100vh;
    padding: 0;
    margin: 0;
    background: var(--bg);
    // background: $accent;

    * {
        font-family: "Roboto", sans-serif;
    }

    .rs-popover {
        z-index: 10;

        * {
            background-color: #292d33 !important;
        }

        div {
            min-width: 200px;
            margin-right: 5px;

            div {
                box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
            }
        }
    }

    .material-icons {
        font-family: 'Material Icons';
    }

    .material-icons-round {
        font-family: 'Material Icons Round';
    }

    .material-icons-outlined {
        font-family: 'Material Icons Outlined';
    }

    .accent {
        color: var(--accent);
    }

    .add-new-button {
        position: fixed;
        bottom: 100px;
        right: 0;
        z-index: 100;
        bottom: 90px;
        right: 15px;
        border-radius: 100%;
        padding: 0;
        background-color: #756ff3;
        @include shadow;

        .flex {
            background-color: transparent;
            margin: 0;
            border-radius: 100%;
            padding: 0;

            span {
                // color: rgba(255, 255, 255, 0.7);
                color: var(--text);
                opacity: .8;
                border: 0;
                font-size: 45px;
            }
        }
    }

    .rs-panel {
        .rs-panel-title {
            color: var(--text);
        }

        svg {
            color: var(--text);
            align-self: flex-start;
            font-size: 25px;
        }

        .rs-panel-btn {
            &.active {
                background-color: transparent;
            }
        }

        button {
            padding: 0;
        }
    }

    .dropdown {
        width: 180px;

        .rs-panel-body {
            background-color: white;
            width: 100%;
            padding: 0;
            border-radius: $borderRadius;
            margin: 0;
        }

        .rs-panel-header {
            padding: 0;
        }

        button {
            background-color: transparent;

            &:hover {
                background-color: transparent;
            }
        }

        p {
            list-style: none;
            border-bottom: 1px solid var(--translucent);
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            display: flex;
            padding: 10px;
            font-size: 16px;
            line-height: 1.2;
            color: var(--blackText);
            align-items: center;
            margin: 0;

            &:first-child {
                border-radius: $borderRadius $borderRadius 0 0;
            }

            &.adam {
                background-color: #ffe2c6;
                color: var(--blackText);
            }

            &.red {
                background-color: $red;
            }

            &.black {
                background-color: black;
                color: white;
            }

            &.gray {
                background-color: gray;
            }

            &.orange {
                background-color: orange;
                color: var(--blackText);
            }

            &.blue {
                background-color: var(--bg);
                color: var(--text);
            }

            &.pink {
                background-color: pink;
            }

            &.yellow {
                background-color: var(--warning);
                color: var(--blackText);
            }

            &.violet {
                background-color: violet;
            }

            &.silver {
                background-color: silver;
                color: var(--blackText);
            }

            &.brown {
                background-color: brown
            }
        }
    }


    @include menu;


    #checkbox-group {
        display: flex;
        align-items: center;

        display: flex;
        flex-wrap: wrap;

        .flex {
            align-items: center;
            margin-right: 15px;
            margin-bottom: 0;
            height: 30px;

            span {
                color: var(--blackText);
                font-weight: 400;
                font-size: 16px;
                display: inline;
                margin-left: 10px;
            }

            .box {
                height: 22px;
                width: 22px;
                border: 1px solid var(--translucent);
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;

                .checkmark-icon {
                    display: none;
                    color: var(--accent);
                }

                &.active {
                    background-color: var(--accent);
                    border: 0;

                    .checkmark-icon {
                        display: block;
                        margin: 0 auto;
                        font-size: 20px;
                        color: white;
                    }

                }
            }

            .checkmark-icon {
                color: var(--bg);
                // margin: 0 15px 0 5px;
                padding: 0;
                line-height: 1;
            }
        }
    }

    #coparent-verification-container {
        p {
            font-weight: 300;
            color: var(--text);
            line-height: 1.3;
        }

        button {
            margin: 0 auto;
            @include button(white, $green);

        }
    }

    #forgot-password-container {
        button {
            @include button(white, $green);
            margin: 30px auto 0 auto;
            width: 200px;

            &.red {
                @include button(white, $red);
                margin-top: 5px;
            }
        }
    }

    #alert-container,
    #confirm-container {
        @include popupContainers;
    }

    #loading-gif {
        height: 100px;
        width: 100px;
        margin: 0 auto;
        display: none;

        &.active {
            display: block;
        }
    }

    #loading-gif-container {
        z-index: 100000000;
        position: relative;
        display: none;
        @include pageContainer;

        &.active {
            display: block;
        }

        #loading-gif {
            @include deadCenter;
            width: 100px;
            height: 100px;
            top: 40%;
            z-index: -1;
        }
    }

    b {
        font-weight: bold;
    }

    p {
        line-height: 1.2;
    }

    .page-container {
        @include pageContainer;
    }

    .modal {
        @include modal;

        &.code-status-modal {
            padding: 15px;
            display: flex;
            align-items: center;

            .modal-content {
                .content {
                    margin-top: auto;
                    margin-bottom: auto;
                    display: block;

                    h4 {
                        color: var(--text);
                        text-align: center;

                    }

                    .flex {
                        margin-bottom: 8px;
                        align-items: center;

                        .right {
                            color: var(--text);
                            width: 55%;
                            line-height: 1.2;
                            font-size: 16px;
                        }

                        .left {
                            width: 35%;
                            color: var(--text);
                            padding: 10px;
                            margin-right: 10px;
                            border-radius: $borderRadius;
                            font-weight: 500;

                            &.adam {
                                background-color: #ffe2c6;
                                color: var(--blackText);
                            }

                            &.red {
                                background-color: $red;
                            }

                            &.black {
                                background-color: black;
                                color: white;
                            }

                            &.gray {
                                background-color: gray;
                            }

                            &.orange {
                                background-color: orange;
                                color: var(--blackText);
                            }

                            &.blue {
                                background-color: var(--bg);
                            }

                            &.pink {
                                background-color: pink;
                                color: var(--blackText);
                            }

                            &.yellow {
                                background-color: var(--warning);
                                color: var(--blackText);
                            }

                            &.violet {
                                background-color: violet;
                            }

                            &.silver {
                                background-color: silver;
                                color: var(--blackText);
                            }

                            &.brown {
                                background-color: brown
                            }
                        }
                    }
                }
            }
        }
    }

    .button-group {
        // width: 90%;
        margin: 0 auto;
        display: flex
    }

    .form {
        @include form;
    }

    .overlay {
        height: 100vh;
        width: 100vw;
        position: fixed;
        left: 0;
        right: 0;
        z-index: -1;
        bottom: 0;
        top: 0;
        // transition: all .3s ease-in-out;
        opacity: 0;

        &.active {
            opacity: 1;

            z-index: 100;
        }
    }

    .screen-title {
        @include screenTitle;
    }

    .flex {
        display: flex;
    }

    // Override rsuite Styles
    @include rSuiteOverrides;

    .instructions {
        color: var(--text);
        opacity: .7;
        font-style: italic;
        text-align: left;
        margin-top: 20px;
        margin-bottom: 10px;
        font-weight: 300;
        line-height: 1.1;
        font-size: 16px;

        &.center {
            @include deadCenter;
        }

        &.gallery {
            margin-top: 0;
        }
    }

    .button {
        @include button;

        &.red {
            @include button(white, $red)
        }
    }
}