// Palette
$bg: "#F8EDE3";
$text: "#8D493A";
$accent: "##D0B8A8";
$lightAccent: '#DFD3C3';
$green: #00b389ee;
$red: #e83e71e8;
$bgRed: #f8d7da;
$translucent: rgba(255, 255, 255, 0.2);
$linkColor: #0096FF;
$warning: #fff3cd;

// Theme
$bgDark: linear-gradient(0deg, hsla(214, 100%, 14%, 1) 0%, hsla(212, 55%, 32%, 1) 100%);