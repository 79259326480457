@mixin shadow {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

@mixin pageContainer {
    padding-bottom: 100px;
    position: fixed;
    width: 100vw;
    padding: 10px;
    height: 100vh;
    max-height: 100vh;
    overflow: auto;
    overflow-x: hidden;
    max-width: 100vw;
    padding-bottom: 300px;
    background: var(--bg);
    background: $accent;
    opacity: 0;
    transition-timing-function: cubic-bezier(.17, .67, .21, .95);
    top: 70px;

    &.active {
        opacity: 1;
        margin-top: 0;
    }

    &#settings-container {
        padding-left: 0;
    }
}


@mixin modalNavArrows {

    .back-arrow,
    .forward-arrow {
        bottom: 0;
        font-size: 40px;
        color: var(--text);
        z-index: 1000;
    }
}

@mixin modal {
    transition: all .3s ease-in-out;
    position: fixed;
    top: 0;
    height: 100vh;
    overflow: hidden;
    left: 0;
    bottom: 0;
    z-index: -1;
    opacity: 0;
    width: 100%;

    #modal-actions {
        width: calc(100% - 30px);
        background-color: rgba(0, 0, 0, 0);
        z-index: 1000;
        display: flex;
        position: fixed;
        justify-content: space-between;
        align-items: center;
        bottom: 15px;
        height: 70px;
        @include modalNavArrows;
        padding: 20px 10px 20px 10px;

        span {
            margin: 0 auto;
        }

        .close {
            border: 0;
            color: $red;
            z-index: 10000;
            font-size: 45px;
        }
    }

    .modal-content {
        width: 100%;
        position: relative;
        overflow: auto;
        top: 0;
        bottom: 0;
        z-index: 1000;

        .action-buttons {
            margin-top: 20px;
            display: flex;
            justify-content: center;


            button {
                @include buttonWithIcon;


                &#mark-as-paid {
                    background-color: $green;
                }
            }
        }

        p {
            color: white;

            &#expense-notes {
                max-height: 150px;
                overflow: scroll;
                line-height: 1.2;
                display: block;

            }
        }

        img {
            height: auto;
            margin: 0 auto;
            z-index: 1000;
            width: auto;
            border-radius: 0;
        }
    }

    &.active,
    &.show {
        display: block;
        z-index: 2147483647;
        opacity: 1;
        -webkit-backdrop-filter: blur(10px);
        background-color: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(20px);
    }

    &.pwa-modal {
        -webkit-backdrop-filter: blur(10px);
        background-color: var(--modalBg);
        backdrop-filter: blur(30px);
        opacity: 1;
        display: none;
        padding: 10px;

        .modal-content {
            width: calc(100vw - 20px);
        }

        &.active {
            display: block;
        }

        h3 {
            color: var(--text);
            line-height: 1.2;
            margin-bottom: 15px;
            ;
            padding: 10px;
            border-radius: $borderRadius;
            font-size: 25px;
            font-weight: 400;
            text-align: center;

            u {
                font-weight: 500;
            }

            span {
                position: relative;
                top: 3px;
                color: var(--compColor);
                ;
            }
        }

        .os-container {
            margin-bottom: 20px;

            &.apple {
                h1 {
                    color: var(--text);
                }
            }

            &.android {
                h1 {
                    color: $green;
                }
            }
        }

        #modal-actions {
            .close {
                font-size: 50px;
                margin-bottom: 10px;
                margin: 0 auto 20px auto;
            }
        }

        button {
            @include button($green);
            width: 100%;
            margin-top: 50px;

            span {
                color: $green;
            }
        }

        h1 {
            font-size: 25px;
            font-weight: 500;
            margin-bottom: 5px;
            line-height: 1;
            color: var(--text);

            ion-icon {
                color: var(--text);
                font-size: 20px;
                visibility: visible;
                top: 10px;
            }

            span {
                color: $green;
                font-size: 20px;
                position: relative;
                top: 5px;


                &.android {
                    color: $green;
                    font-size: 25px;
                }

            }
        }

        p,
        i {
            font-weight: 400;
            font-style: italic;
            margin: 0 0 0 0;

        }

        i {
            font-style: italic;
            font-size: 16px;
            color: var(--compColor);
            ;
        }


        li {
            margin-left: -10px;
            margin-bottom: 8px;
            line-height: 1.2;
            font-weight: 400;
            font-size: 16px;
            color: var(--text);

            span {
                position: relative;
                top: 3px;
            }
        }
    }
}

@mixin screenTitle {
    color: var(--text);
    font-size: 30px;
    text-align: center;
    line-height: 1;
    opacity: .9;
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 600;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    z-index: 100;
    background: var(--bgColor);
    text-transform: uppercase;

    svg {
        margin-left: 5px;
        fill: var(--lightAccent);
    }
}

@mixin buttonWithIcon($color: white, $bgColor: $green) {
    border: 0;
    border-radius: 5px;
    color: $color;
    padding: 18px 0;
    line-height: 1;
    vertical-align: middle;
    display: flex;
    align-items: center;
    font-weight: 400;
    background-color: $bgColor;

    span {
        font-size: 20px;
        margin-left: 5px;
        line-height: 1;
    }
}

@mixin pills {
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;

    span {
        border-radius: 40px;
        border: 1px solid var(--accent);
        color: var(--accent);
        padding: 2px 10px;
        margin-right: 5px;
        font-size: 14px;
        margin-bottom: 5px;
        margin-left: 0;
        opacity: 1;
        display: flex;
        align-items: center;
        height: 25px;

        &:first-child {
            margin-right: 5px;
            line-height: 1;
            padding: 2px 10px;
            margin-bottom: 5px;
        }

        &.active {
            background-color: var(--accent);
            color: var(--text);
        }
    }
}

@mixin gradientButtonBg {
    // background-image: linear-gradient(to right, $accent 0%, $mainBlue 51%, $lightBlue 100%);
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    box-shadow: 0 0 5px $lightBlue;
}

@mixin button($color: white, $bgColor: $green) {
    line-height: 1;
    border: 0;
    border-radius: 0;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $bgColor;
    border-radius: 5px;
    color: $color;
    min-width: 50%;
    height: 35px;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center;
    opacity: 1;
    padding: 0 10px;
    vertical-align: middle;

    &.center {
        margin: 0 auto;
    }

    span {
        font-size: 18px;
        margin-left: 10px;
    }
}

@mixin form {
    margin-top: 20px;

    &.show {
        display: block;
    }

    input,
    textarea {
        margin: 0 auto;
        border-radius: 5px;
        width: 100%;
        border: 0;
        background-color: white;
        border: 1px solid var(--translucent);
        border-radius: $borderRadius;
        outline: 0;
        color: var(--blackText);
        font-weight: 400;
        padding-left: 0;
        font-size: 16px;
        min-height: 40px;
        padding: 5px;
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: var(--text) !important;

        &.rs-input {
            margin-bottom: 0;
        }

        &:focus,
        &:valid,
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            // -webkit-background-clip: text;
            // -webkit-text-fill-color: #ffffff;
        }

        &::placeholder {
            color: var(--blackText);
            opacity: .4;
            font-style: italic;
            font-weight: 400;
            font-size: 16px;
        }
    }

    textarea {
        height: 150px;
        border: 1px solid var(--translucent);
        padding: 10px;
        border-radius: $borderRadius;
    }

    label {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: var(--accent);
        font-weight: 500;
        font-size: 16px;
        min-width: 50%;
        line-height: 1;
        margin-bottom: 5px;
        text-transform: uppercase;
        padding-left: 5px;

        span {
            color: var(--yellow);
        }
    }

    .button-group {
        margin: 0 auto;
        justify-content: center;
        margin-top: 30px;

        button {
            @include button;

            &.green {
                margin-right: 10px;
                @include button(white, $green);
            }

            &.red {
                background-color: transparent;
                @include button(white, $red);
            }
        }
    }


}

@mixin deadCenter() {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 200px;
    width: 100vw;
    text-align: center;
    margin: 0;
    padding: 0;
}

@mixin rSuiteOverrides() {
    .rs-input-group-addon {
        display: none;
    }
}

@mixin menu {
    #menu-container {
        .menu-icon-container {
            width: 100%;
            position: fixed;
            bottom: 0;
            display: none;
            align-items: center;
            justify-content: space-between;
            z-index: 10000;
            padding: 0 20px;
            height: 80px;
            transition: all .5px ease-in-out;
            border-radius: 0;
            background-color: var(--bg);

            &.open {
                display: flex;
                backdrop-filter: blur(0);
                z-index: 10000;
            }

            .icon-and-text-container {
                margin-bottom: 10px;



                .text {
                    color: whitesmoke;
                    text-align: center;
                    font-size: 12px;
                    font-weight: 500;
                    padding-top: 2px;
                    opacity: .5;
                    color: whitesmoke;
                }

                &.active {
                    span {
                        opacity: 1;
                        color: var(--accent, #756ff3);
                    }

                    .text {
                        font-weight: bold;
                        opacity: 1;
                        color: var(--accent, #756ff3);
                    }
                }

                &.menu {
                    p {
                        position: relative;
                        top: -5px;
                        opacity: .8;
                        color: whitesmoke;
                    }
                }

                span {
                    position: relative;
                    z-index: 10;
                    user-select: none;
                    border-radius: 100%;
                    -webkit-tap-highlight-color: transparent;
                    font-size: 35px;
                    transition: all .3s;
                    display: block;
                    background-color: transparent;
                    color: whitesmoke;
                    border-radius: 0;
                    border: 0;
                    text-align: center;
                    margin: 0;
                    width: calc(100vw / 6);
                    opacity: .5;

                    &:last-child {
                        font-size: 35px;
                    }

                    &::focus {
                        outline: 0;
                    }

                    &.menu-icon {
                        transition: all .7s;
                        text-align: center;
                        justify-content: center;
                        z-index: -1;
                        display: block;
                        opacity: 1;
                        border-radius: 100%;
                        max-height: 40px;
                        position: relative;
                        top: -5px;
                        color: whitesmoke;
                        margin-bottom: 0;
                        font-size: 45px;
                        opacity: .8;
                    }
                }
            }


        }

        #menu {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            transition: .7s;
            position: relative;
            left: -100vw;
            width: 0;
            overflow-x: hidden;
            opacity: 1;
            height: 100vh;
            z-index: 1000;
            background-color: var(--menuBg);
            overflow: hidden;
            transition-timing-function: cubic-bezier(.17, .67, .21, .95);
            box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);

            &.open {
                opacity: 1;
                z-index: 10000;
                width: 75vw;
                left: 0;

                .back {
                    bottom: 90px;
                    right: 15px !important;
                    display: flex !important;
                }

                * {
                    transition: .1s;
                }

            }

            #menu-user-name {
                color: var(--text);
                text-align: center;
                margin: 20px auto 0 auto;
            }

            .content {
                padding-bottom: 200px;
                overflow-y: auto;
                max-height: 100vh;

                /* width */
                &::-webkit-scrollbar {
                    display: none;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                    display: none;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                    display: none;
                }

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    display: none;
                }

                #pwa-steps {
                    @include button(white, $accent);
                    font-weight: 400;
                    width: 130px;
                    font-size: 14px;
                    margin: 20px auto 0 auto;
                    min-width: 0;
                    padding: 0;
                    height: 30px;
                    z-index: 10000;
                    background-color: #ccc;
                    color: #28282B;
                }

                #menu-title {
                    height: 150px;
                    width: 100%;
                    margin-bottom: 10px;

                    .contents {
                        display: flex;
                        justify-content: center;
                        flex-wrap: wrap;
                        align-items: center;
                        margin-top: auto;
                        height: 100%;
                        margin-bottom: auto;
                    }

                    .title-icon {
                        color: var(--accent);
                        font-size: 50px;
                        position: relative;
                        top: 10px;
                        opacity: .5;
                        width: 100%;
                        margin-bottom: 0;
                        text-align: center;
                    }

                    p {
                        color: var(--text);
                        text-align: center;
                        font-size: 22px;
                        line-height: 1;
                        letter-spacing: 1px;
                        margin: 0;
                        display: block;
                        padding: 0;
                        width: 100%;
                        font-family: "Playwrite IT Moderna", cursive;
                        font-optical-sizing: auto;
                        font-style: normal;
                        font-weight: 300;
                        font-style: normal;

                        span {
                            opacity: .5;
                            font-size: 20px;
                            opacity: .7;
                            margin-left: 0;
                            font-family: "Playwrite IT Moderna", cursive;
                            font-optical-sizing: auto;
                            font-style: normal;
                            font-optical-sizing: auto;
                            font-weight: 300;
                            font-style: normal;
                            position: relative;
                            left: 3px;
                        }

                        &:first-child {
                            // margin-top: auto;
                            width: 100%;
                        }

                        &:last-child {
                            margin-bottom: auto;
                            margin-left: -10px;
                        }
                    }
                }

                #menu-items {
                    width: 100%;
                    margin-bottom: auto;

                    .menu-item {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 55px;
                        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                        margin-top: 0;
                        margin-bottom: auto;

                        &:nth-child(1) {
                            border-top: 1px solid rgba(255, 255, 255, 0.1);
                            margin-top: 0;
                        }

                        &.active {

                            p {
                                font-weight: 500;
                            }

                            p {
                                opacity: 1;
                            }
                        }

                        p {
                            line-height: 1;
                            color: var(--text);
                            font-size: 20px;
                            text-align: left;
                            width: 200px;
                            display: flex;
                            margin-right: auto;
                            align-items: center;
                            opacity: .7;
                        }

                        span {
                            font-size: 25px;
                            color: var(--text);
                            margin-right: 8px;
                            margin-left: 10px;
                            opacity: .7;
                            color: var(--accent)
                        }
                    }
                }

                .back {
                    opacity: .8;
                    z-index: 1000000;
                    margin-left: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: fixed;
                    right: 20px;
                    color: var(--text);
                    font-size: 35px;
                    z-index: 1000000;
                    margin-left: auto;
                    background-color: var(--accent);
                    border-radius: 100%;
                    display: none;
                    font-size: 45px;
                }
            }
        }
    }
}

@mixin popupContainers {
    border-radius: $borderRadius;
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: -1;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    transition: all .5s ease-in-out;
    opacity: 0;
    transition-timing-function: cubic-bezier(.17, .67, .21, .95);

    &.show {
        z-index: 1000;
        opacity: 1;

        .text {
            top: 0;
        }
    }

    &#alert-container {
        .text {
            background-color: $green;
            z-index: 100;

            * {
                color: var(--text);
            }
        }

        &.error {
            .text {
                background-color: $red;
            }
        }
    }

    &#confirm-container {
        .text {
            background-color: #fff3cd;

            * {
                color: #856404;
            }
        }

        .button-group {
            width: 100%;
            display: flex;
            justify-content: center;

            button {
                border: 1px solid #ffeeba;
                width: 40%;
                margin: 0;
                display: flex;
                height: 40px;
                text-align: center;

                &:first-child {
                    margin-left: auto;
                    margin-right: 8px;
                    margin-top: 0;
                }

                &:last-child {
                    margin-right: auto;
                }

                span {
                    font-size: 18px;
                    position: relative;
                    top: 0px;

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    &.desktop {
        p {
            margin-top: 0;
        }
    }

    .text {
        box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
        background: #d53d70;
        height: auto;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 auto;
        padding: 10px;
        z-index: 100;
        width: 100vw;
        top: -70px;
        position: fixed;
        transition: all .2s ease-in-out;
        min-height: 70px;
        opacity: 1;
        transition-timing-function: cubic-bezier(.17, .67, .21, .95);
        color: white;

        &.success {
            background-color: $green;
        }

        p {
            // color: var(--text);
            font-weight: 500;
            font-size: 20px;
            width: 100%;
            z-index: 10000;
        }
    }
}