@keyframes expandHeight {
    from {
        max-height: 0;
    }

    to {
        max-height: 1000px;
    }
}

@keyframes shrinkHeight {
    from {
        max-height: 1000px;
    }

    to {
        max-height: 50px;
    }
}