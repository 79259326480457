#report-container {
    .code-status-info-icon {
        color: var(--text);
        position: relative;
        margin-right: 8px;
    }

    .primary-dropdowns {
        position: relative;

        svg {
            font-size: 25px;
        }

        .dept-column {
            svg {
                position: relative;
                top: -3px;
                font-size: 25px;
                margin-left: 5px;
                color: white;

                &.active {
                    fill: var(--warning);
                    color: var(--warning)
                }
            }

            .rs-panel-group {
                width: 100%;
            }
        }

        .dropdown {
            width: 50%;

            &:first-child {
                margin-right: 10px;
            }



            &.code-status {
                button {
                    background-color: transparent;
                }
            }

            ul {
                width: 100%;
            }
        }
    }

    .section {
        overflow: hidden;
        transition: max-height .5s ease-in-out;

        label {
            padding: 0;
        }


        .rs-panel {

            .pills {
                @include pills;
            }

            button {
                color: var(--text);
                font-size: 20px;
                background-color: var(--accent);
                padding: 12px 10px;
                font-weight: 500;
                border-radius: $borderRadius;
                align-items: center;
                transition: max-height .5s ease-in-out;
                border: 0;
                margin: 0;
            }

            span {
                font-size: 30px;
                margin-left: auto;

                &:first-child {
                    margin: 0;
                    margin-right: 10px;
                    opacity: 1;
                }

                &.rs-panel-title {
                    font-size: 20px;
                }
            }

            &.rs-panel-in {
                .form {
                    @include shadow;
                }

                .rs-panel-header,
                button {
                    border-radius: $borderRadius $borderRadius 0 0;
                }

                .form {
                    opacity: 1;
                    max-height: 1000px;
                }
            }

            .rs-panel-body,
            .rs-panel-header {
                padding: 0;
            }

            .form {
                background-color: white;
                margin: 0;
                border-radius: 0 0 $borderRadius $borderRadius;
                padding: 10px;

                .isolation-status {
                    .status-input {
                        display: none;

                        &.active {
                            display: block;
                            width: 100%;
                        }
                    }
                }

                .flex {
                    align-items: flex-start;

                    &.double {
                        width: 100%;


                        .input-label-group {

                            width: 50%;

                            &:first-child {
                                margin-right: 10px;
                            }
                        }

                        .full-width {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}